/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useSelector, useDispatch } from "react-redux";
import { CurrentUser, FamilyHasAccess } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Grid } from "@mui/material";
import { ArrowLeftCircleFill, ArrowRightCircleFill, Download } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../features/Fetch/Auth";
import { useEffect } from "react";
import { pageLoad } from "../App";
import { Features } from "../constants";

const UploadComplete = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let navigate = useNavigate();

	if (!FamilyHasAccess(user, Features.Reports)) {
		navigate("/");
		return <></>;
	}

	return (
		<>
			<Grid container>
				<Grid item xs={8} >
					<h2 className="mt-2 text-app">Budget & Category Upload Complete</h2>
				</Grid>
			</Grid>
			<Grid container rowSpacing={3} columnSpacing={2} className='p-1 m-0 w-100'>
				{user.isHoH && (<>
					<Grid item xs={12}>
						<h3>Your upload is complete.</h3>
					</Grid>
					<Grid item xs={12}>
						A file should have downloaded, titled "Results.xlsx". If any records failed to be processed, there will be red text in this document detailing why it failed.
					</Grid>
					<Grid item xs={12} className="mt-5">
						<h3>Click the button below to go back and try another upload.</h3>
					</Grid>
					<Grid item xs={3} className="btn btn-list btn-report text-app border border-app" onClick={() => navigate("/Upload/BC/Settings")}>
						<ArrowLeftCircleFill /> Back
					</Grid >
				</>)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};
}

export default UploadComplete;