import { PlusLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useItems } from "../../features/Fetch/Items";
import { ModalProps, setProps } from "../../features/Slices/modalSlice";
import { BudgetItem, CategoryItem, TransactionItem } from "../../types";
import Category from "../ListItems/Category";
import EditCategory from "../Modals/EditCategory";

const FamilyCategories = (props: any) => {
	let repo = useItems();
	let dispatch = useDispatch();

	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);
	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);

return (<>
	{categories.slice().sort((a, b) => a.description.localeCompare(b.description)).map(category =>
		<Category Item={category} OnClick={() => OpenCategoryModal(category.categoryId)} />
	)}
	<div className="col-12">
		<div className="btn btn-category btn-list text-app" onClick={() => OpenNewCategoryModal()}>
			<h3>
				<PlusLg /> Add New Category
			</h3>
		</div>
	</div>
</>);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	//Categories
	async function SaveCategory(item: CategoryItem) {
		repo.UpdateCategoryAsync(item, item.categoryId === 0, false);
		HideModal();
	}

	function DeleteCategory(item: CategoryItem) {
		repo.UpdateCategoryAsync(item, false, true);
		HideModal();
	}

	function OpenNewCategoryModal() {
		let category = {
			categoryId: 0,
			description: '',
			isViewTransactions: true
		};
		modal = {
			...modal,
			Body: <EditCategory Item={category} OnSave={SaveCategory} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenCategoryModal(idx: number) {
		let category = categories.find(t => t.categoryId === idx)!;

		let tCount = transactions?.filter(t => t.budgetCategoryAmounts.map(c => c.categoryId).includes(category.categoryId)).length;
		let bCount = budgets?.filter(b => b.categoryIds.map(c => c.id).includes(category.categoryId)).length;
		let cCount = checkRequests?.filter(t => t.budgetCategoryAmounts.map(c => c.categoryId).includes(category.categoryId)).length;
		let inUse = tCount > 0 || bCount > 0 || cCount > 0;

		if (category) {
			modal = {
				...modal,
				Body: <EditCategory Item={category} OnSave={SaveCategory} OnDelete={inUse ? undefined : DeleteCategory} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}
}

export default FamilyCategories;