import { Grid } from "@mui/material";
import React from "react";
import { PencilSquare, XSquare } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { BudgetItem, TimeframeItem } from "../../types";

const Timeframe = (props: any) => {
    let timeframe: TimeframeItem = props.Item;
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);

	let bCount = budgets?.filter(b => b.timeframeId === timeframe.timeframeId).length;
	let counts = [] as string[];
	if (bCount > 0) {
		counts.push(`${bCount} Budget${bCount > 1 ? 's' : ''}`);
	}
	let inUse = counts.length > 0;

	let editable = timeframe.timeframeId > 0;

	return (
		<Grid container className={`py-2 ${props.IsPrimary ? '' : 'text-app cursor-pointer'}`} style={{ textAlign: 'left' }} onClick={editable ? props.OnClick : () => { }}>
			<Grid item xs={1} className={editable ? inUse ? "text-warning" : "text-success" : 'text-danger'} style={{ textAlign: 'right', paddingRight: '10px' }} >
				{editable ? <PencilSquare /> : <XSquare />}
			</Grid>
			<Grid item xs={11} xl={7} >
				<h3>{timeframe.longDescription}</h3>
			</Grid>
			<Grid item className="text-black" style={{ textAlign: 'right' }} xs={12} xl={4} >
				{counts.join(', ')}
			</Grid>
		</Grid >
	);
}

export default Timeframe;