/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useSelector, useDispatch } from "react-redux";
import { CurrentUser, FamilyHasAccess } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Grid } from "@mui/material";
import { ArrowRightCircleFill, Download } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../features/Fetch/Auth";
import { useEffect } from "react";
import { pageLoad } from "../App";
import { Features } from "../constants";

const DocumentUpload = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let navigate = useNavigate();

	if (!FamilyHasAccess(user, Features.Reports)) {
		navigate("/");
		return <></>;
	}

	return (
		<>
			<Grid container>
				<Grid item xs={8} >
					<h2 className="mt-2 text-app">Budget & Category Upload</h2>
				</Grid>
			</Grid>
			<Grid container rowSpacing={3} columnSpacing={2} className='p-1 m-0 w-100'>
				{user.isHoH && (<>
					<Grid item xs={12}>
						The Budget & Category Upload feature is designed as quick solution for adding or editing multiple budgets and categories at once.
						<br />This feature is non-destructive, which means there is no risk of data loss when using this feature. However, keep in mind that <b>if you use this feature to rename categories, they will be renamed everywhere in the site, including Transactions to which they have already been assigned.</b>
					</Grid>
					<Grid item xs={12}>
						To use this feature, fill out the below template. Pay close attention to the instructions and be sure to reference the Budget Categories Example tab for quick mapping of multiple budgets to multiple shared categories.
					</Grid>
					<Grid item xs={2}>
					</Grid>
					<Grid item xs={3} className="btn btn-list btn-report text-app border border-app">
						<Link className="text-decoration-none" to="/Shekl Upload Template.xlsx" target="_blank" download><Download /> Download</Link>
					</Grid >
					<Grid item xs={12}>
						<h3>Click the Download link above to download template.</h3>
					</Grid>
					<Grid item xs={12}>
						Once you have filled out the form, select Continue below to select your upload options.
					</Grid>
					<Grid item xs={9}>
					</Grid>
					<Grid item xs={3} className="btn btn-list btn-report text-app border border-app mt-5" onClick={() => navigate("/Upload/BC/Settings")}>
						Continue <ArrowRightCircleFill />
					</Grid >
				</>)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};
}

export default DocumentUpload;