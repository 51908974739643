import { PlusLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../features/Fetch/Auth";
import { useItems } from "../../features/Fetch/Items";
import { ModalProps, setProps } from "../../features/Slices/modalSlice";
import { CurrentUser, UserEmailItem } from "../../types";
import UserEmail from "../ListItems/UserEmail";
import EditUserEmail from "../Modals/EditUserEmail";

const MyEmailAddresses = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let dispatch = useDispatch();

	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let user: CurrentUser = useSelector((state: any) => state.userData.value);

	let primaryEmail = user.emails!.find(e => e.email === user.email);

return (<>
	<UserEmail Item={primaryEmail} OnClick={() => { }} IsPrimary />

	{user.emails!.filter(e => e.email !== user.email).slice().sort((a, b) => a.email.localeCompare(b.email)).map(email =>
		<UserEmail Item={email} OnClick={() => OpenUserEmailModal(email.userEmailId)} />
	)}
	<div className="col-12">
		<div className="btn btn-userEmail btn-list text-app" onClick={() => OpenNewUserEmailModal()}>
			<h3>
				<PlusLg /> Add New Email Address
			</h3>
		</div>
	</div>
</>);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	//UserEmails
	async function SaveUserEmail(item: UserEmailItem) {
		let response: UserEmailItem = await repo.UpdateUserEmailAsync(item, item.userEmailId === 0, false);
		if (response.isDuplicate) {
			modal = {
				...modal,
				Body: <EditUserEmail Item={response} OnSave={SaveUserEmail} OnDelete={response.userEmailId > 0 ? DeleteUserEmail : undefined} Close={HideModal} />,
			}
			dispatch(setProps(modal));
		} else {
			auth.setUserContext();
			HideModal();
		}
	}

	function DeleteUserEmail(item: UserEmailItem) {
		repo.UpdateUserEmailAsync(item, false, true);
		auth.setUserContext();
		HideModal();
	}

	function OpenNewUserEmailModal() {
		let userEmail = {
			userEmailId: 0,
			email: '',
			isDuplicate: false,
			isVerified: false
		} as UserEmailItem;
		modal = {
			...modal,
			Body: <EditUserEmail Item={userEmail} OnSave={SaveUserEmail} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenUserEmailModal(idx: number) {
		let userEmail = user.emails!.find(t => t.userEmailId === idx);
		if (userEmail) {
			modal = {
				...modal,
				Body: <EditUserEmail Item={userEmail} OnSave={SaveUserEmail} OnDelete={DeleteUserEmail} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}
}

export default MyEmailAddresses;