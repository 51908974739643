import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Features } from "../../constants";
import { useAuth } from "../../features/Fetch/Auth";
import { CurrentUser, FamilyFeatureValue, UserItem } from "../../types";
import Error from "../Error";
import Amount from "../Inputs/Amount";
import TextInput from "../Inputs/TextInput";
import Toggle from "../Inputs/Toggle";

const FamilySettings = (props: any) => {
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	let [error, setError] = useState('');

	let me = users?.find(u => u.isMe);
	let setting = {
		FirstName: me?.firstName,
		LastName: me?.lastName,
		Email: user.email,
		Password: '',
		ConfirmPassword: '',
		Rate: user.mileageRate,
		AllowUnassigned: user.unassignedBudgets,
		IncludeAllUsersInBudgets: user.includeAllUsersInBudgets,
		FamilyName: user.familyName
	};
	let [settings, setSettings] = useState(setting);
	if (settings.FirstName !== setting.FirstName && !settings.FirstName) {
		let me = users?.find(u => u.isMe);
		let s = {
			...settings,
			FirstName: me?.firstName,
			LastName: me?.lastName,
		};
		setSettings({ ...s });
	}

return (<>
	<Grid container spacing={2} className="my-5">
		{user.isHoH && user.isActive && <>
			<TextInput Required Id="inputFamilyName" Label="Family Name" Value={settings.FamilyName} Size={12} OnChange={(e: any) => FamilyNameChange(e.target.value)} />
			<Amount NoDollar Size={12} Value={settings.Rate} Label="Mileage Rate" OnChange={(val: any) => RateChange(val.target.value)} />
			<Grid xs={1}></Grid>
			<Toggle Size={12} OnChange={AllUnassignedChange} Checked={settings.AllowUnassigned} Label={`Allow limited users to assign ${FamilyFeatureValue(user, Features.TransactionTitle)}s to all budgets`} />
			<Toggle Size={12} OnChange={IncludeAllUsersInBudgetsChange} Checked={settings.IncludeAllUsersInBudgets} Label={`Automatically include all users in new budgets`} />
		</>}
		<Grid xs={3}></Grid>
		<Grid xs={6} style={{ display: ShowSave() ? "block" : "none" }}>
			<Button variant="contained" className="centered col-12 bg-success btn-large mt-4" onClick={SaveSettings}>SAVE</Button>
		</Grid>
		<Grid item xs={12}>
			<Error Text={error} />
		</Grid>
	</Grid>
</>);

	//User and Fmaily Settings
	function RateChange(newValue: string) {
		setSettings({ ...settings, Rate: parseFloat(newValue) });
	}
	function AllUnassignedChange(event: any, newValue: boolean) {
		setSettings({ ...settings, AllowUnassigned: newValue });
	}
	function IncludeAllUsersInBudgetsChange(event: any, newValue: boolean) {
		setSettings({ ...settings, IncludeAllUsersInBudgets: newValue });
	}
	function FamilyNameChange(newValue: string) {
		setSettings({ ...settings, FamilyName: newValue });
	}

	function ShowSave() {
		let should = false;
		if (settings.Rate !== setting.Rate || settings.AllowUnassigned !== setting.AllowUnassigned || settings.IncludeAllUsersInBudgets !== setting.IncludeAllUsersInBudgets || settings.FamilyName !== setting.FamilyName) {
			should = true;
		}
		return should;
	}

	async function SaveSettings() {
		if (settings.Rate !== setting.Rate || settings.AllowUnassigned !== setting.AllowUnassigned || settings.IncludeAllUsersInBudgets !== setting.IncludeAllUsersInBudgets || settings.FamilyName !== setting.FamilyName) {
			let result = await auth.updateUserContext({
				...user,
				mileageRate: settings.Rate,
				unassignedBudgets: settings.AllowUnassigned,
				familyName: settings.FamilyName,
				includeAllUsersInBudgets: settings.IncludeAllUsersInBudgets
			});
			setError(result);
		}
		auth.setUserContext();
	}
}

export default FamilySettings;