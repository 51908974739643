import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../features/Fetch/Auth";
import { useItems } from "../../features/Fetch/Items";
import { CurrentUser, SelectableItem, UserItem } from "../../types";
import Error from "../Error";
import Dropdown from "../Inputs/Dropdown";
import PasswordInput from "../Inputs/PasswordInput";
import TextInput from "../Inputs/TextInput";

const MyProfile = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let navigate = useNavigate();

	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	let [error, setError] = useState("");
	let [hasLength, setHasLength] = useState(false);
	let [hasLower, setHasLower] = useState(false);
	let [hasUpper, setHasUpper] = useState(false);
	let [hasNumber, setHasNumber] = useState(false);
	let [hasSpecial, setHasSpecial] = useState(false);

	let isValidPassword = hasLength && hasLower && hasUpper && hasNumber && hasSpecial;

	let me = users?.find(u => u.isMe);
	let setting = {
		FirstName: me?.firstName,
		LastName: me?.lastName,
		Email: user.email,
		Password: '',
		ConfirmPassword: '',
		Rate: user.mileageRate,
		AllowUnassigned: user.unassignedBudgets,
		IncludeAllUsersInBudgets: user.includeAllUsersInBudgets,
		FamilyName: user.familyName
	};
	let [settings, setSettings] = useState(setting);

return (<>
	<Grid container spacing={2} className="my-5">
		<TextInput Required Id="inputFirst" Label="First Name" Value={settings.FirstName} Size={6} OnChange={(e: any) => FirstNameChange(e.target.value)} />
		<TextInput Required Id="inputLast" Label="Last Name" Value={settings.LastName} Size={6} OnChange={(e: any) => LastNameChange(e.target.value)} />
		{props.UseEmail &&
			<Dropdown Size={12}
				Class="mb-0 bg-white"
				Label="Primary Email"
				Options={GetEmailDict()}
				Value={user.emails!.find(e => e.email === settings.Email)!.userEmailId}
				OnChange={EmailChange} />
		}
		<PasswordInput Id="inputPassword" Label="Create New Password" Value={settings.Password} Size={12} OnChange={(e: any) => PasswordChange(e.target.value)} />
		<PasswordInput Id="confirmPassword" Label="Confirm New Password" Value={settings.ConfirmPassword} Size={12} OnChange={(e: any) => ConfirmPasswordChange(e.target.value)} />
		<Grid item xs={7} textAlign="left" style={{ display: settings.Password.length > 0 ? "block" : "none" }}>
			{(!hasLength || !hasLower || !hasUpper || !hasNumber || !hasSpecial) &&
				<>
					<div>Password must have the following:</div>
					<div style={{ color: "orange", display: hasLength ? "none" : "block" }}>8 or more characters</div>
					<div style={{ color: "orange", display: hasLower ? "none" : "block" }}>Lowercare letter</div>
					<div style={{ color: "orange", display: hasUpper ? "none" : "block" }}>Capital letter</div>
					<div style={{ color: "orange", display: hasNumber ? "none" : "block" }}>Number</div>
					<div style={{ color: "orange", display: hasSpecial ? "none" : "block" }}>Special character</div>
				</>
			}
		</Grid>
		<Grid xs={3}></Grid>
		<Grid xs={6} style={{ display: ShowSave() ? "block" : "none" }}>
			<Button variant="contained" className="centered col-12 bg-success btn-large mt-4" onClick={SaveSettings}>SAVE</Button>
		</Grid>
		<Grid xs={12} style={{ display: (user.email !== settings.Email && ShowSave()) ? "block" : "none" }}>
			<div className="text-warning mx-3 centered">
				Changing your primary email address will require you to log in to the site again.
			</div>
		</Grid>
		<Grid item xs={12}>
			<Error Text={error} />
		</Grid>
	</Grid>
</>);

	function GetEmailDict() {
		let result: SelectableItem[] = [];
		user.emails!.filter(e => e.isVerified).forEach(e => result.push({
			Id: e.userEmailId,
			Value: e.email
		}));
		return result;
	}

	//User and Fmaily Settings
	function FirstNameChange(newValue: string) {
		setSettings({ ...settings, FirstName: newValue });
	}
	function LastNameChange(newValue: string) {
		setSettings({ ...settings, LastName: newValue });
	}
	function EmailChange(newValue: string) {
		setSettings({ ...settings, Email: user.emails!.find(e => e.userEmailId === parseInt(newValue))!.email });
	}
	function PasswordChange(newValue: string) {
		let validationRegex = [
			{ regex: /.{8,}/ },
			{ regex: /[0-9]/ },
			{ regex: /[a-z]/ },
			{ regex: /[A-Z]/ },
			{ regex: /[^A-Za-z0-9]/ },
		];

		setHasLength(validationRegex[0].regex.test(newValue));
		setHasNumber(validationRegex[1].regex.test(newValue));
		setHasLower(validationRegex[2].regex.test(newValue));
		setHasUpper(validationRegex[3].regex.test(newValue));
		setHasSpecial(validationRegex[4].regex.test(newValue));

		setSettings({ ...settings, Password: newValue });
	}
	function ConfirmPasswordChange(newValue: string) {
		setSettings({ ...settings, ConfirmPassword: newValue });
	}

	function ShowSave() {
		let should = false;
		me = users.find(u => u.isMe);
		if (me !== undefined && (settings.FirstName !== me.firstName || settings.LastName !== me.lastName)) {
			should = true;
		}
		if (settings.Email !== setting.Email) {
			should = true;
		}
		if (settings.Password) {
			if (!settings.Password || !isValidPassword) {
				error = 'Your password must fit the complexity requirements.';
				return false;
			} else if (settings.Password !== settings.ConfirmPassword) {
				error = 'Your passwords must match.';
				return false;
			} else {
				should = true;
			}
		}
		return should;
	}

	async function SaveSettings() {
		me = users.find(u => u.isMe);
		if (me !== undefined && (settings.FirstName !== me.firstName || settings.LastName !== me.lastName)) {
			await repo.UpdateUserAsync({
				...me,
				firstName: settings.FirstName ?? me.firstName,
				lastName: settings.LastName ?? me.lastName
			}, false, false);
		}
		if (settings.Password) {
			if (!settings.Password || !isValidPassword) {
				setError('Your password must fit the complexity requirements.');
			} else if (settings.Password !== settings.ConfirmPassword) {
				setError('Your passwords must match.');
			} else {
				let result = await repo.UpdateUserPasswordAsync({ FirstName: "First", LastName: "Last", Code: "NotReal", Email: settings.Email, Password: settings.Password, PasswordConfirm: settings.ConfirmPassword });
				setError(result);
			}
		}
		if (settings.Email !== setting.Email) {
			await repo.UpdatePrimaryEmailAsync(settings.Email ?? setting.Email ?? "");
			auth.logout();
			navigate("/");
		} else {
			auth.setUserContext();
		}
	}
}

export default MyProfile;