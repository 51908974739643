/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CurrentUser, FamilyHasAccess, SelectableItem2, UserItem } from "../types";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useItems } from "../features/Fetch/Items";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import Dropdown from "../components/Inputs/Dropdown";
import { Features } from "../constants";
import MyProfile from "../components/Settings/MyProfile";
import MyEmailAddresses from "../components/Settings/MyEmailAddresses";
import FamilySettings from "../components/Settings/FamilySettings";
import FamilyCategories from "../components/Settings/FamilyCategories";
import FamilyTimeframes from "../components/Settings/FamilyTimeframes";
import FamilyAccounts from "../components/Settings/FamilyAccounts";

const Settings = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let dispatch = useDispatch();
	let navigate = useNavigate();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	let location = useLocation();
	let page = location.pathname.toLowerCase().substring(location.pathname.lastIndexOf("/") + 1);
	if (page === "settings") navigate("/Settings/Profile");

	let me = users?.find(u => u.isMe);
	let setting = {
		FirstName: me?.firstName,
		LastName: me?.lastName,
		Email: user.email,
		Password: '',
		ConfirmPassword: '',
		Rate: user.mileageRate,
		AllowUnassigned: user.unassignedBudgets,
		IncludeAllUsersInBudgets: user.includeAllUsersInBudgets,
		FamilyName: user.familyName
	};
	let [settings, setSettings] = useState(setting);
	if (settings.FirstName !== setting.FirstName && !settings.FirstName) {
		let me = users?.find(u => u.isMe);
		let s = {
			...settings,
			FirstName: me?.firstName,
			LastName: me?.lastName,
		};
		setSettings({ ...s });
	}

	let useEmail = FamilyHasAccess(user, Features.UserEmails);
	let useCategories = user.isHoH && user.isActive && FamilyHasAccess(user, Features.Categories);
	let useTimeframes = user.isHoH && user.isActive && FamilyHasAccess(user, Features.Timeframes);
	let useAccounts = user.isHoH && user.isActive && FamilyHasAccess(user, Features.Accounts);

	let sections: SelectableItem2[] = [
		{ Id: "profile", Value: 'My Profile' }
	];
	if (useEmail) {
		sections.push({ Id: "emails", Value: 'My Email Addresses' });
	}
	if (user.isHoH) {
		sections.push({ Id: "family", Value: 'Family Settings' });
	}
	if (useCategories) {
		sections.push({ Id: "categories", Value: 'Family Categories' });
	}
	if (useTimeframes) {
		sections.push({ Id: "timeframes", Value: 'Family Timeframes' });
	}
	if (useAccounts) {
		sections.push({ Id: "accounts", Value: 'Family Accounts' });
	}

return (<>
	{users && user && <>
		<Dropdown Size={4}
			Class="mb-5 right"
			Variant="standard"
			Options={sections}
			Value={page}
			OnChange={SetSection} />
		{page === "profile" &&
			<MyProfile />
		}
		{page === "family" &&
			<FamilySettings />
		}
		{useEmail && page === "emails" &&
			<MyEmailAddresses />
		}
		{useCategories && page === "categories" &&
			<FamilyCategories />
		}
		{useTimeframes && page === "timeframes" &&
			<FamilyTimeframes />
		}
		{useAccounts && page === "accounts" &&
			<FamilyAccounts />
		}
	</>}
</>);

	function SetSection(page: number) {
		navigate(`/Settings/${page}`);
	}
	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};
}

export default Settings;