import { PlusLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useItems } from "../../features/Fetch/Items";
import { ModalProps, setProps } from "../../features/Slices/modalSlice";
import { BudgetItem, DateTime, TimeframeItem } from "../../types";
import Timeframe from "../ListItems/Timeframe";
import EditTimeframe from "../Modals/EditTimeframe";

const FamilyTimeframes = (props: any) => {
	let repo = useItems();
	let dispatch = useDispatch();

	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);

return (<>
	{timeframes.slice().sort((a, b) => a.description.localeCompare(b.description)).map(timeframe =>
		<Timeframe Item={timeframe} OnClick={() => OpenTimeframeModal(timeframe.timeframeId)} />
	)}
	<div className="col-12">
		<div className="btn btn-timeframe btn-list text-app" onClick={() => OpenNewTimeframeModal()}>
			<h3>
				<PlusLg /> Add New Timeframe
			</h3>
		</div>
	</div>
</>);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	//Timeframes
	function SaveTimeframe(item: TimeframeItem) {
		repo.UpdateTimeframeAsync(item, item.timeframeId === 0, false);
		HideModal();
	}

	function DeleteTimeframe(item: TimeframeItem) {
		repo.UpdateTimeframeAsync(item, false, true);
		HideModal();
	}

	function OpenNewTimeframeModal() {
		let timeframe = {
			timeframeId: 0,
			days: 0,
			months: 0,
			description: '',
			startDate: new DateTime()
		};
		modal = {
			...modal,
			Body: <EditTimeframe Item={timeframe} OnSave={SaveTimeframe} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenTimeframeModal(idx: number) {
		let timeframe = timeframes.find(t => t.timeframeId === idx)!;

		let bCount = budgets?.filter(b => b.timeframeId === timeframe.timeframeId).length;
		let inUse = bCount > 0;

		if (timeframe) {
			modal = {
				...modal,
				Body: <EditTimeframe Item={timeframe} OnSave={SaveTimeframe} OnDelete={inUse ? undefined : DeleteTimeframe} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}
}

export default FamilyTimeframes;