import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useItems } from "../../features/Fetch/Items";
import { ModalProps, setProps } from "../../features/Slices/modalSlice";
import { AccountItem, UserItem } from "../../types";
import Divider from "../Inputs/Divider";
import Link from "../Inputs/Link";
import RemoveLink from "../Inputs/RemoveLink";
import UpdateLink from "../Inputs/UpdateLink";
import Account from "../ListItems/Account";
import EditAccount from "../Modals/EditAccount";

const FamilyAccounts = (props: any) => {
	let repo = useItems();
	let dispatch = useDispatch();

	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let accounts: AccountItem[] = useSelector((state: any) => state.accounts.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);

	let instit: number = 0;

return (<>
	&nbsp;
	{accounts
		.filter(a => a.financialInstitution)
		.slice()
		.sort((a, b) => a.description.localeCompare(b.description))
		.sort((a, b) => a.differentiator! - b.differentiator!)
		.sort((a, b) => a.financialInstitution!.localeCompare(b.financialInstitution!))
		.map(account =>
			<>
				{!IsCurrentInstitution(account) && (<>
					<Divider
						Text={account.financialInstitution}
						key={`institution${account.financialInstitution}`} />
					<Grid container justifyContent="right">
						<UpdateLink Account={account} />
						<RemoveLink Id={account.differentiator} />
					</Grid>
				</>)}
				<Account Item={account} OnClick={() => OpenAccountModal(account.accountId)} />
			</>
		)}
	<Link />
</>);

	function IsCurrentInstitution(account: AccountItem) {
		if (instit !== account.differentiator) {
			instit = account.differentiator!;
			return false;
		}
		return true;
	}
	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	//Accounts
	async function SaveAccount(item: AccountItem) {
		await repo.UpdateAccountAsync(item, item.accountId === 0, false);
		repo.GetAccountsAsync();
		HideModal();
	}

	function DeleteAccount(item: AccountItem) {
		repo.UpdateAccountAsync(item, false, true);
		HideModal();
	}

	function OpenAccountModal(idx: number) {
		let account = accounts.find(t => t.accountId === idx);
		if (account) {
			modal = {
				...modal,
				Body: <EditAccount Item={account} Users={users} OnSave={SaveAccount} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}
}

export default FamilyAccounts;