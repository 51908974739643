/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useSelector, useDispatch } from "react-redux";
import { CurrentUser, DateTime, FamilyHasAccess, VisuallyHiddenInput } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { Upload } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../features/Fetch/Auth";
import { useEffect, useState } from "react";
import { pageLoad } from "../App";
import { Features } from "../constants";
import Toggle from "../components/Inputs/Toggle";
import Date from "../components/Inputs/Date";
import Divider from "../components/Inputs/Divider";
import saveAs from "file-saver";

const BudgetCategoryUploadSettings = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let navigate = useNavigate();
	let dispatch = useDispatch();
	let modal: ModalProps = useSelector((state: any) => state.modal.value);
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	let [overlay, setOverlay] = useState(false);

	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				//repo.GetLocalItems();
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let [importType, setImportType] = useState(1);
	let [resetDate, setResetDate] = useState(new DateTime());

	if (!FamilyHasAccess(user, Features.Reports)) {
		navigate("/");
		return <></>;
	}

	return (
		<>
			<Grid container>
				<Grid item xs={8} >
					<h2 className="mt-2 text-app">Budget & Category Upload</h2>
				</Grid>
			</Grid>
			<Grid container rowSpacing={3} columnSpacing={2} className='p-1 m-0 w-100'>
				{user.isHoH && (<>
					<Grid item xs={12}>
						<b>By default, the Budget & Category Upload feature will only process new records.</b> Existing records will be ignored. With the options below, you can choose to allow the feature to update some existing categories and budget-category relationships. Regardless of which options you choose, new records will be processed, and we will return a copy of your uploaded document detailing any records that we had to ignore.
					</Grid>
					<Divider />
					<Grid item xs={12} className="mt-3">
						<Toggle Size={12} OnChange={Update} Checked={importType > 1} Label=<b>Allow existing Categories and/or Budget-Category relationships to be updated.</b> />
					</Grid >
					{importType > 1 &&
						<Grid item xs={12}>
							<Toggle Size={12} OnChange={Reset} Checked={importType === 3} Label=<b>Reset existing Budget-Category relationships (Must enter a date below).</b> />
						</Grid >
					}
					{importType === 3 &&
						<Grid item xs={6}>
							<Date Size={12} OnChange={UpdateDate} Label="Reset Date" />
						</Grid >
					}
					<Divider />
					<Grid item xs={12}>
						Once you have selected the correct options, select Upload below. We will process your request and return a copy of your uploaded document, detailing any items we were unable to update.
					</Grid>
					<Grid item xs={2}>
					</Grid>
					<Grid item xs={3} className="btn btn-list btn-report text-app border border-app mt-5">
						<Button component="label" variant="text" startIcon={<Upload />}>
							<b>Upload</b>
							<VisuallyHiddenInput type="file" onChange={UploadFile} />
						</Button>
					</Grid >
				</>)}
			</Grid>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={overlay}>
				<CircularProgress />
			</Backdrop>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function Update(event: any, newValue: boolean) {
		if (newValue) {
			setImportType(2);
		} else {
			setImportType(1);
		}
	}

	function Reset(event: any, newValue: boolean) {
		if (newValue) {
			setImportType(3);
		} else {
			setImportType(2);
		}
	}

	function UpdateDate(newValue: any) {
		setResetDate(new DateTime(newValue));
	}

	async function UploadFile(event: React.ChangeEvent<HTMLInputElement>) {
		setOverlay(true);
		const reader = new FileReader();
		reader.onload = async () => {
			let response = await repo.UploadBudgetCategoryExcelAsync(importType, resetDate, { file: (reader.result! as string).split(',')[1] });
			setOverlay(false);
			saveAs(await response.blob() as Blob, "Results.xlsx");
			navigate("/Upload/Done");
		}
		reader.readAsDataURL(event.target.files![0]);
	}
}

export default BudgetCategoryUploadSettings;