import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalProps, setProps } from "../../features/Slices/modalSlice";

const Modal = () => {
    let dispatch = useDispatch();
	let props: ModalProps = useSelector((state: any) => state.modal.value);
    let isMobile: ModalProps = useSelector((state: any) => state.isMobile.value);

	let handleBeforeUnload = (event: BeforeUnloadEvent) => {
		if (props.IsOpen) {
			const e = event || window.event;
			e.preventDefault();
			e.returnValue = "You have unsaved changes. Are you sure you want to leave?";
			return "You have unsaved changes. Are you sure you want to leave?";
		}
	};

	window.onbeforeunload = handleBeforeUnload;

    if (props.IsOpen && !props.WasOpen) {
        dispatch(setProps({ ...props, WasOpen: true }))
	}

    if (props.IsOpen && props.WasOpen) {
		return (
            <div className={isMobile ? "modals" : "desktop-modals"}>
                <div className={`wiz edit ${(props.IsOpen && props.WasOpen ? "active" : "hidden")}`}>
					<div className="edit-page" role="document">
						<div className="modal-content">
							{props.Body}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
}

export default Modal;